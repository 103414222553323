import { pathOr, map } from "ramda";

const defaults = {
	host: "https://www.instagram.com/",
	username: '',
	tag: '',
};

const getInstagramFeed = async (opts) => {
	const options = Object.assign({}, defaults, opts);
	const { username, tag, host } = options;
	const url = !username ? host + "explore/tags/" + tag : host + username;
	return new Promise((resolve, reject) => {
		const xhr = new XMLHttpRequest();
		xhr.onload = function () {
			if (xhr.readyState === 4) {
				if (xhr.status === 200) {
					// eslint-disable-next-line
					let data = xhr.responseText.split("window._sharedData = ")[1].split("<\/script>")[0];
					data = JSON.parse(data.substr(0, data.length - 1));
					data = data.entry_data.ProfilePage || data.entry_data.TagPage || null;
					if (data === null) {
						reject("InstagramFeed: Request error. No data retrieved: " + xhr.statusText);
					} else {
						data = data[0].graphql.user || data[0].graphql.hashtag;
						const result = map(
							({ node = {}}) => {
								const url = defaults.host + 'p/' + node.shortcode;
								let type;
								let image;

								switch(node.__typename){
									case "GraphSidecar":
										type = "sidecar";
										image = pathOr(null, ['thumbnail_resources', 4, 'src'], node);
										break;
									case "GraphVideo":
										type = "video";
										image = pathOr(null, ['thumbnail_src'], node);
										break;
									default:
										type = "image";
										image = pathOr(null, ['thumbnail_resources', 4, 'src'], node);
								}
								return ({
									type,
									image,
									url
								})
							},
							pathOr([], [username ? 'edge_owner_to_timeline_media' : 'data.edge_hashtag_to_media', 'edges'], data)
						);
						resolve(result);
					}
				} else {
					reject("InstagramFeed: Request error. Response: " + xhr.statusText);
				}
			}
		};

		xhr.open("GET", url, true);
		xhr.send();
	});
};

export default getInstagramFeed;