import { useEffect, useState } from "react";
import { assocPath, filter, groupBy, ifElse, length, map, pathOr, pipe, prop, times, concat } from "ramda";
import getInstagramFeed from "../utils/instagram";

const randomizeImage = images => assocPath(
	['random'],
	true,
	assocPath(['relationships', 'media'], images[Math.floor(Math.random() * images.length)], {})
);

const getInstagramImage = (image) => assocPath(
	['instagram'],
	true,
	image
);

const useGroupedArticles = (props) => {
	const { formState, articles, tags, bigKey, smallKey, bigImages, smallImages, posts: postArray, withInstagram = false } = props;
	const [loading, setLoading] = useState(false);
	const [mappedArticles, setArticles] = useState([]);

	useEffect(() => {
		const calculateItems = async () => {
			await new Promise(resolve => setTimeout(resolve, 300));
			// make post have same fields as article
			const posts = (postArray || []).map(assocPath(['relationships', 'projet'], { name: 'Poste', id: '__belazar_post' }));
			let instagramFeed = [];

			// fetch data from instagram if needed
			if (withInstagram) {
				try {
					instagramFeed = await getInstagramFeed({ username: process.env.GATSBY_BELAZAR_INSTAGRAM_PAGE || 'belazar.explore' });
				} catch (e) {
					console.warn(e);
				}
			}

			// concat post and articles (for actualite page)
			const preMappedArticles = concat(articles, posts);
			const filteredArticles = pipe(
				filter(tag => formState[tag.id]),
				ifElse(length,
					t => preMappedArticles
						.filter(article => t
							.map(prop('id'))
							.some(id => map(prop('id'), pathOr([], ['relationships', 'tags'], article)).includes(id))),
					() => preMappedArticles
				),
				groupBy(pathOr([], ['relationships', 'projet', 'name'])),
			)(tags);

			const bigArticles = pathOr([], [bigKey], filteredArticles);
			const smallArticles = pathOr([], [smallKey], filteredArticles);

			// length of groups with 3 images
			const groupLength = Math.max(bigArticles.length, Math.ceil(smallArticles.length / (withInstagram ? 1 : 2)));


			// return mapped items
			setArticles(
				times((i) => {
					const bigItem = { item: bigArticles[i] || randomizeImage(bigImages), random: !bigArticles[i], big: true, };

					const smallItems = times(
						n => (withInstagram && n === 0)
							// if we use instagram first image from insta
							? getInstagramImage(instagramFeed[i % instagramFeed.length])
							// second is always from drupal - post, projet or random depending on page
							: smallArticles[withInstagram ? (i) : (i * 2 + n)] || randomizeImage(smallImages),
						2
					).map(item => ({ item, big: false, random: !!item.random, instagram: !!item.instagram }));

					if (i % 2) {
						// for odd - big item in the end
						return [...smallItems, bigItem]
					} else {
						// for even - in the beginning
						return [bigItem, ...smallItems]
					}
				}, groupLength)
			);
		};

		setLoading(true);
		calculateItems()
			.catch(e => console.warn(e))
			.finally(() => { setLoading(false); })
		// eslint-disable-next-line
	}, [articles, tags, formState, bigImages, smallImages, postArray]);

	return { loading, mappedArticles }
};

export default useGroupedArticles;