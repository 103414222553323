import { Flex, Box } from 'rebass';
import React from "react";
import Arrow from "../../images/icons/arrow-bottom.svg";

const PreHomeIcon = ({ children, color, hovered, active, ...props }) => {
	const activeStyles = active ? {
		opacity: 0,
		transition: 'opacity 1s ease',
	} : {
		opacity: 1,
	};

	return (
		<Flex
			width={64}
			height={64}
			alignItems="center"
			justifyContent="center"
			{...props}
			sx={{
				...props.sx,
				display: ['none !important', null, 'inline-flex !important'],
				position: 'relative',
				...activeStyles,
			}}
		>
			<Box sx={{
				position: 'absolute',
				left: 0,
				top: 0,
				height: '100%',
				width: '100%',
				svg: {
					transform: 'rotate(-90deg)',
					circle: {
						stroke: color,
						strokeDasharray: 201,
						strokeDashoffset: hovered ? 402 : 201,
						transition: hovered ? 'stroke-dashoffset 0.5s linear 0.3s' : 'stroke-dashoffset 0.3s linear',
					}
				}
			}}>
				<svg height="64" width="64">
					<circle className="circle" cx="32" cy="32" r="31" strokeWidth="1" fillOpacity="0"/>
				</svg>
			</Box>
			<Box sx={{
				opacity: hovered ? 1 : 0,
				transition: 'opacity 0.3s ease-in',
			}}>
				{children}
			</Box>
			<Box
				sx={{
					position: 'absolute',
					bottom: 0,
					left: '50%',
					transform: 'translate(-50%, 60%)',
					opacity: hovered ? 1 : 0,
					transition: hovered ? 'opacity 0.1s ease-in 0.7s' : 'opacity 0.1s ease-in',
					svg: {
						path: {
							fill: color,
						}
					}
				}}
			>
				<Arrow  />
			</Box>
		</Flex>
	)
};

export default PreHomeIcon;
