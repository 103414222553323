import React, { useEffect, useState, useRef } from "react";
import SEO from "../components/seo";
import Bar from "../components/bar";
import { Box, Flex, Heading, Image } from "rebass";
import { useColorMode } from "theme-ui";
import ReactPlayer from 'react-player';
import posed from "react-pose";
import theme from "../gatsby-plugin-theme-ui";
import PreHomeIcon from "../components/preHomeIcon";
import black from '../video/bg_black.mp4';
import bgDesktop from '../video/bg_desktop.mp4';
import bgMobile from '../video/bg_mobile.mp4';
import bgFallback from '../video/bg_fallback.jpg';
import bgFallbackMob from '../video/bg_fallback_mob.jpg';
import { graphql } from "gatsby";
import { path } from 'ramda';
import { useMediaQuery } from 'react-responsive';
import useGroupedArticles from '../hooks/useGroupedArticles';
import Blur from '../components/blur';
import Fullpage from '../components/fullpage';
import { isMobile } from 'react-device-detect';

const Button = ({ video, children, active, clicked, title, subtitle, textColor, sx, ...props }) => {
  const [hovered, setHovered] = useState(false);
  const hoveredStyles = hovered ? {
    width: [`100%`, null, '100%'],
    height: ['85%', null, '100%'],
    '> picture': { opacity: 1, transition: 'opacity 2s'},
    svg:  { opacity: 1, transition: 'opacity 2s',  }
  } : {};

  return (
    <Flex
      as="button"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      p={0}
      sx={{
        width: [`100%`, null, '100%'],
        height: ['100%', null, '100%'],
        opacity: active ? 0 : 1,
        transition: '1s all ease',
        position: 'relative',
        '> picture': { opacity: 0 },
        'svg': { display: ['none', null, 'inline-block'] },
        ...hoveredStyles,
        ...sx
      }}
      {...props}
    >
      <Box
        as="picture"
        height="100%"
        width="100%"
        sx={{ position: 'absolute', left: 0, top: 0, zIndex: 0, opacity: 0, transition: 'opacity 1s', video: { objectFit: 'cover' } }}
      >
        <ReactPlayer
          url={video}
          loop
          playsinline
          playing
          muted
          width="100%"
          height="100%"
          config={{
            file: {
              attributes: {
                id: 'videoId',
                preload: 'auto',
              },
            },
          }}
        />
      </Box>
      <Box sx={{
        zIndex: 1,
        opacity: clicked ? 0 : 1,
        transition: 'opacity 0.3s'
      }}>
        <Bar color={textColor === 'black' ? 'white' : 'black'} bgColor={textColor} fontSize={[2, null, 4]}>{subtitle}</Bar>
        <Heading as="h2" color={textColor} fontSize={[6, null, 9]} maxWidth={450} mb={[3, null, 4]}>{title}</Heading>
        {children({ hovered, active })}
      </Box>
    </Flex>
  );
};

const PoseFlex = posed(Flex)({
  active: {
    zIndex: 18,
  },
  closed: {
    zIndex: 0,
    applyAtEnd: {
      display: 'none',
    }
  }
});

const BIG_ARTICLE = 'Etude de cas';
const SMALL_ARTICLE = 'Projet';

const IndexPage = ({ data }) => {
  const {
    accueil,
    placeholderImages: { items: { bigImages = [], smallImages = [] } },
    articles: { items: articles },
    tags: { items: tags },
    config,
  } = data;

  // eslint-disable-next-line
  const [color, setColorMode] = useColorMode();
  const [linksOpen, setLinksOpen] = useState(false);
  const [formState, setFormState] = useState({});
  const [isPreviewShown, setIsPreviewShown] = useState(null);
  const [soundEnabled, setSoundEnabled] = useState(false);
  const [soundActive, setSoundActive] = useState(false);
  const [soundHovered, setSoundHovered] = useState(false);
  const [isTabHidden, setIsTabHidden] = useState(false);
  const [videoError, setVideoError] = useState(false);
  const [videoReady, setVideoReady] = useState(false);

  const { loading, mappedArticles } = useGroupedArticles({
    formState,
    articles,
    tags,
    bigKey: BIG_ARTICLE,
    smallKey: SMALL_ARTICLE,
    bigImages,
    smallImages
  });

  const playerRef = useRef(null);
  const videoElement = (typeof window !== 'undefined') && document.getElementById('videoId');

  useEffect(() => {
    const sessionValue = sessionStorage.getItem('isPreviewShown');

    if (isTabletOrMobile && !sessionValue) {
      const timeoutId = setTimeout(() => {
        setColorMode('dark');
        setLinksOpen(true);
        sessionStorage.setItem('isPreviewShown', 'true');
        setIsPreviewShown('true');
      }, 2000);

      return () => {
        clearTimeout(timeoutId)
      };
    }

    setIsPreviewShown(sessionValue);
  }, []);

  useEffect(() => {
    const handleSuspend = () => {
      setVideoError(true);
    };

    if (videoElement) {
      videoElement.addEventListener('suspend', handleSuspend);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('suspend', handleSuspend);
      }
    };
  }, [videoElement]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabHidden((prev) => !prev);
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const isTabletOrMobile = useMediaQuery({ maxWidth: theme.breakpoints[1] }) || isMobile;
  const soundBtnProps = {
    soundEnabled: soundEnabled,
    setSoundEnabled: setSoundEnabled,
    soundActive: soundActive,
    setSoundActive: setSoundActive,
    soundHovered: soundHovered,
    setSoundHovered: setSoundHovered,
    color: theme.colors.white,
    iconSize: isTabletOrMobile ? 35 : 45,
    onClick: () => {
      if (playerRef.current) {
        playerRef.current.volume = +soundEnabled;
      }
    },
    styles: {
      width: 50,
      height: 50,
      border: 'none',
    },
  };

  return (
    <>
      <SEO title="Home"/>
      {isPreviewShown && (
        <Fullpage
          hideScroll
          soundBtnProps={soundBtnProps}
          background={({ blurred }) => (
            <Blur
              active={blurred}
              grayscale={false}
              darken={true}
              sx={{
                height: '100%',
                width: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                willChange: 'filter'
              }}
            >
              <Box
                as="picture"
                height="100%"
                width="100%"
                sx={{
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  zIndex: 0,
                  opacity: 1,
                  transition: 'opacity 2s',
                  video: { objectFit: 'cover' },
                  backgroundColor: 'rgb(224, 69, 5)',
                  ...((!videoReady || videoError) ? {
                    backgroundImage: `url(${!isTabletOrMobile ? bgFallback : bgFallbackMob})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  } : {}),
                }}
              >
                <ReactPlayer
                  ref={playerRef}
                  url={isTabletOrMobile ? bgMobile : bgDesktop}
                  loop
                  playsinline
                  style={{ ...(!videoReady ? { display: 'none' } : {}) }}
                  playing={!isTabHidden && !blurred}
                  muted={!soundEnabled}
                  config={{
                    file: {
                      attributes: {
                        id: 'videoId',
                        preload: 'auto',
                      },
                    },
                  }}
                  width="100%"
                  height="100%"
                  onError={() => setVideoError(true)}
                  onReady={() => setVideoReady(true)}
                />
              </Box>
            </Blur>
          )}
          sections={[
            () => null,
            // ({ blurred }) => <Flex
            //   justifyContent={['center', null, 'flex-end']}
            //   alignItems="center"
            //   height="100%"
            //   width="100%"
            // >
            //   <Flex
            //     justifyContent="stretch"
            //     alignItems="stretch"
            //     flexDirection="column"
            //     height={[null, null, "80%"]}
            //     width={[`calc(100% - 5%)`, 500, '82%']}
            //     sx={{
            //       position: 'relative',
            //       left: [`${theme.space[1] / 2}px`, null, 1],
            //     }}
            //   >
            //     {!isTabletOrMobile && (
            //       <Flex
            //         sx={{
            //           position: 'static',
            //           bottom: 0,
            //           left: '50%',
            //           opacity: 1,
            //           display: 'flex',
            //         }}
            //         alignItems="center"
            //         mt={-4}
            //         py={4}
            //         alignSelf="flex-start"
            //       >
            //         <Flex
            //           alignItems="center"
            //           fontSize={1}
            //           sx={{ textTransform: 'uppercase', marginLeft: '30px' }}
            //           py={0}
            //           px={0}
            //           mr={3}
            //           bg={'transparent'}
            //         >
            //           {accueil.sliderDescription}
            //         </Flex>
            //       </Flex>
            //     )}
            //
            //     <Filters
            //       visible={blurred || !isTabletOrMobile}
            //       setFormState={setFormState}
            //       tags={tags} formState={formState}
            //     />
            //
            //     <Masonry groups={mappedArticles} loading={loading}/>
            //   </Flex>
            // </Flex>
          ]}
        />
      )}

      {!isPreviewShown && (
        <PoseFlex
          justifyContent="stretch"
          alignItems="stretch"
          flexDirection={['column', null, 'row']}
          sx={{ position: 'fixed', top: 0, left: 0, height: '100%', width: '100%', zIndex: 18 }}
          p={[2, null, 3]}
          pose={linksOpen ? 'closed' : 'active'}
        >
          <Button
            backgroundColor="black"
            active={linksOpen && color === 'dark'}
            clicked={linksOpen}
            onClick={() => {
              setColorMode('dark');
              setLinksOpen(true);
              sessionStorage.setItem('isPreviewShown', 'true');
              setIsPreviewShown('true');
            }}
            video={black}
            title={config.titleDark}
            subtitle={config.subtitleDark}
            textColor="white"
          >
            {({ active, hovered }) => (
              <PreHomeIcon active={active} hovered={hovered} color="white">
                <Image
                  src={path(['icons', 'dark', 'file', 'url'], config)}
                  sx={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                  }}
                />
              </PreHomeIcon>
            )}
          </Button>
        </PoseFlex>
      )}
    </>
  )
};

export default IndexPage;

export const query = graphql`
  query PreHomeQuery {
    config: configPagesPrehome {
      subtitleDark: field_dark_sub_title
      titleDark: field_dark_title
      subtitleDefault: field_white_sub_title
      titleDefault: field_white_title
      icons: relationships {
        default: field_white_icon {
          file: localFile {
            url: publicURL
          }
        }
        dark: field_dark_icon {
          file: localFile {
            url: publicURL
          }
        }
      }
    }
    accueil: nodeAccueil {
      sliderDescription: field_dark_description_brut
    }
    articles: allNodeArticle(filter: {relationships: {field_projet: {name: {regex: "/Projet|Etude de cas/"}}}}, sort: { order: DESC, fields: created }) {
      items: nodes {
        title_dark: field_dark_titre
        subtitle_dark: field_dark_sub_title
        description_dark: field_dark_description_brut
        subtitle: field_sub_title
        description: field_description_brut
        id
        title
        url: belazar_original_url
        relationships {
          media: field_media {
            relationships {
              image: field_media_image {
                localFile {
                  sharp: childImageSharp {
                    fluid(maxWidth: 900, quality: 85) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          projet: field_projet {
            name
            id
          }
          tags: field_tags {
            id
            name
          }
        }
      }
    }
    tags: allTaxonomyTermTags {
      items: nodes {
        name
        id
        relationships {
          articles: node__article {
            id
          }
        }
      }
    }
    placeholderImages: configPagesCarouselImages {
      items: relationships {
        bigImages: field_big_images {
          relationships {
            image: field_media_image {
              localFile {
                sharp: childImageSharp {
                  fluid(maxWidth: 900, quality: 85) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        smallImages: field_small_images {
          relationships {
            image: field_media_image {
              localFile {
                sharp: childImageSharp {
                  fluid(maxWidth: 450, quality: 85) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
